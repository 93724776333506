const prod = {
  API_URL: 'https://getlanded.uc.r.appspot.com'
}

const dev = {
  API_URL: process.env.REACT_APP_API_URL || 'http://127.0.0.1:3010'
}
export const config =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_API_URL ? dev : prod

console.log(`Using NODE_ENV: ${process.env.NODE_ENV}`)
console.log(`Version: alpha-08`)
