import React, { useState, useEffect, useRef, lazy, Suspense } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { Helmet } from 'react-helmet'
// @material-ui/core components
import { config } from '../../env'
const { API_URL } = config

// @mui material components
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Button, Link, Typography } from '@mui/material'

// Material Kit 2 PRO React themes
import theme from 'assets/theme'
import Header from './Sections2.0/Header'
const Footer = lazy(() => import('./Sections2.0/Footer'))
const StickyBottomBar = lazy(() => import('./Sections2.0/StickyBottomBar'))
const StickyTopBar = lazy(() => import('./Sections2.0/StickyTopBar'))
const Bragbar = lazy(() => import('./Sections2.0/Bragbar'))
const Carousel = lazy(() => import('./Sections2.0/Carousel'))
const CTA = lazy(() => import('./Sections2.0/CTA'))
const Features = lazy(() => import('./Sections2.0/Features'))
const TextAndImage = lazy(() => import('./Sections2.0/TextAndImage'))
const TextOnly = lazy(() => import('./Sections2.0/TextOnly'))
const ImageOnly = lazy(() => import('./Sections2.0/ImageOnly'))
const Steps = lazy(() => import('./Sections2.0/Steps'))
const Ingredients = lazy(() => import('./Sections2.0/Ingredients'))
const PageHeader = lazy(() => import('./Sections2.0/PageHeader'))
const Products = lazy(() => import('./Sections2.0/Products'))
const Testimonials = lazy(() => import('./Sections2.0/Testimonials'))
import convertMetrics from 'utils/convertMetrics'
import MetricWrapper from 'components/MetricWrapper/MetricWrapper'

// import sectionsPageStyle from 'assets/jss/material-kit-pro-react/views/sectionsPageStyle.js'

// const useStyles = makeStyles(sectionsPageStyle)

export default function SectionsPage() {
  const [pageMetricId, setPageMetricId] = useState()
  const [metricMiddlemanId, setMetricMiddlemanId] = useState()
  const [pageData, setPageData] = useState()
  const [componentsData, setComponentsData] = useState([])
  const [componentMetricsPayload, setComponentMetricsPayload] = useState({})
  const [currComponentMetric, setCurrComponentMetric] = useState()
  const [dynamicComponents, setDynamicComponents] = useState()
  const [pageStart, setPageStart] = useState()

  const [footerHeight, setHFooterHeight] = useState(0)
  const footerRef = useRef(null)

  useEffect(() => {
    // setHFooterHeight(footerRef.current.clientHeight)
  })

  useEffect(() => {
    // var href = window.location.href.substring(window.location.href.lastIndexOf('#') + 1)
    // if (window.location.href.lastIndexOf('#') > 0) {
    //   document.getElementById(href).scrollIntoView()
    // }

    window.addEventListener('scroll', updateView)
    updateView()

    return function cleanup() {
      window.removeEventListener('scroll', updateView)
    }
  })
  const updateView = () => {
    var contentSections = document.getElementsByClassName('cd-section')
    //var navigationItems = document.getElementById('cd-vertical-nav').getElementsByTagName('a')

    for (let i = 0; i < contentSections.length; i++) {
      //var activeSection = parseInt(navigationItems[i].getAttribute('data-number'), 10) - 1
      if (
        contentSections[i].offsetTop - window.innerHeight / 2 < window.pageYOffset &&
        contentSections[i].offsetTop + contentSections[i].scrollHeight - window.innerHeight / 2 >
          window.pageYOffset
      ) {
        contentSections[i].classList.add('is-selected')
        //console.log(contentSections[i].id)
        //setActiveSection('test')
        //navigationItems[activeSection].classList.add('is-selected')
      } else {
        contentSections[i].classList.remove('is-selected')
        //navigationItems[activeSection].classList.remove('is-selected')
      }
    }
  }

  const sendComponentMetrics = async () => {
    try {
      const metrics = convertMetrics(currComponentMetric, componentMetricsPayload)
      if (metrics.length > 0) {
        await axios.patch(`${API_URL}/component-metrics/update`, {
          metrics
        })
      }
    } catch (e) {
      console.error(e)
      console.error(e.response)
    }
  }

  const addComponentMetric = (componentMetricId, payload) => {
    setComponentMetricsPayload((prev) => ({ ...prev, [componentMetricId]: payload }))
  }

  const handleBuyNow = async () => {
    var selected = document.getElementsByClassName('is-selected')
    await sendMetrics(true)
    console.log('Selected Component id: ' + selected[0].id)
    sendComponentMetrics()
  }

  useEffect(() => {
    startTracking()
  }, [])
  useEffect(() => queryPage(), [])
  useEffect(() => queryComponents(), [pageData])
  useEffect(() => buildPage(), [componentsData])

  const startTracking = () => {
    setPageStart(new Date())
  }

  const sendMetrics = async (ctaClicked = false) => {
    try {
      const endTime = new Date()
      const timeDiff = endTime - pageStart
      const secondsElapsed = Math.round(timeDiff / 1000)
      await axios.patch(`${API_URL}/page-metrics/update`, {
        pageMetricId: pageMetricId,
        timeOnPageSeconds: secondsElapsed,
        ctaClicked
      })
    } catch (e) {
      console.error(e)
      console.error(e.response)
    }
  }

  // TODO use CNAME for this instead
  const getProjectId = () => {
    const domainParts = window.location.host.split('.')
    if (domainParts[1] === 'lemieuxskincare') {
      return 'lms-alpha'
    }

    if (domainParts[1] === 'reach-digital') {
      return 'lms-alpha'
    }

    // staging/demo site
    if (domainParts[1] === 'autoconvert') {
      // TODO make a demo site
      //return 'lms-alpha'
    }
    //domainParts.length === 3
  }

  const queryPage = () => {
    ;(async () => {
      try {
        const projectId = getProjectId()
        console.log(`Using projectId: ${projectId}`)

        const response = await axios.get(`${API_URL}/pages/project/${projectId}`)
        const page = _.cloneDeep(response.data.page)
        const pageMetricResponse = await axios.post(`${API_URL}/page-metrics`, { pageId: page._id })
        setPageMetricId(pageMetricResponse.data.pageMetric._id)
        const metricMiddlemanResponse = await axios.post(`${API_URL}/metric-middlemen`, {
          pageId: page._id,
          pageMetricId: pageMetricResponse.data.pageMetric._id
        })
        setMetricMiddlemanId(metricMiddlemanResponse.data.metricMiddleman._id)
        setPageData(page)
      } catch (e) {
        console.error(e)
        console.error(e.response)
      }
    })()
  }

  const queryComponents = () => {
    if (!pageData) return
    ;(async () => {
      const componentIds = pageData.componentIds

      // For each componentId, get the component Data
      const promises = componentIds.map((id) => axios.get(`${API_URL}/components/${id}`))
      const componentArr = (await Promise.all(promises)).map((coll) => coll.data.component)

      // null check components
      componentArr.forEach((c, i) => {
        if (c === null) {
          console.warn(`componentId "${componentIds[i]}" is null!`)
        }
      })

      // filter out any null, undefined, empty
      const noNullsArr = componentArr.filter((n) => n)
      setComponentsData(_.cloneDeep(noNullsArr))
    })()
  }

  const buildPage = () => {
    if (!componentsData) return

    var sectionsArray = []
    const buttonStyle = {
      fontSize: '18px',
      fontWeight: 'bold',
      borderRadius: '4px',
      boxShadow: 'none'
    }

    componentsData.forEach((c, i) => {
      if (c === null) return

      const style = {}

      // we've set the className to cd-section so we can track it
      const className = 'cd-section'

      if (c.backgroundImage) {
        //style.backgroundImage = `url(${c.backgroundImage})`
        style.backgroundImage = ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.4),
            rgba(gradients.dark.state, 0.4)
          )}, url(${c.backgroundImage})`

        style.backgroundPosition = 'center'
        style.backgroundSize = 'cover'
        style.backgroundRepeat = 'no-repeat'
      }

      c.backgroundColor && (style.backgroundColor = c.backgroundColor)
      let section
      switch (c.type) {
        case 'header':
          section = (
            <Header
              className={className}
              logo={c.headerData.logo}
              centerLogo={c.headerData.centerLogo}
              cta={
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    textTransform: 'none',
                    ...buttonStyle,
                    color: c.headerData.buttonColor,
                    backgroundColor: c.headerData.buttonBgColor,
                    fontSize: '14px',
                    padding: '5px'
                  }}
                  // href={c.headerData.cta}
                  href={pageData.redirectUrl}
                >
                  BUY THE BUNDLE
                </Button>
              }
              key={c._id}
              id={c._id}
            />
          )
          break
        case 'sticky-top-bar':
          section = (
            <StickyTopBar className={className} key={c._id} id={c._id}>
              <Link
                display="flex"
                flex={1}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
                href={c.stickyTopBarData.link}
              >
                <Typography variant="body2" color="#fff">
                  {c.stickyTopBarData.title}
                </Typography>
                <Typography variant="body2" color="#fff" fontWeight="bold">
                  {c.stickyTopBarData.description}
                </Typography>
              </Link>
            </StickyTopBar>
          )
          break
        case 'sticky-bottom-bar':
          section = (
            <StickyBottomBar
              className={className}
              key={c._id}
              id={c._id}
              cta={
                <Button
                  variant="contained"
                  flex={1}
                  sx={{
                    width: '100%',
                    textTransform: 'none',
                    borderRadius: '0px',
                    position: 'relative',
                    ...buttonStyle,
                    color: c.stickyBottomBarData.buttonColor,
                    backgroundColor: c.stickyBottomBarData.buttonBgColor
                  }}
                  //href={c.stickyBottomBarData.ctaLink}
                  href={pageData.redirectUrl}
                >
                  BUY THE BUNDLE
                </Button>
              }
              offset={c.stickyBottomBarData.offset}
              riskText={c.stickyBottomBarData.sellRiskText}
            />
          )
          break
        case 'bragbar':
          section = (
            <Bragbar
              className={className}
              key={c._id}
              id={c._id}
              heading={c.bragBarData.heading}
              description={c.bragBarData.description}
              cta={
                c.bragBarData.cta ? (
                  <Button
                    variant="contained"
                    flex={1}
                    href={c.bragBarData.cta}
                    sx={{
                      width: '100%',
                      textTransform: 'none',
                      borderRadius: '0px',
                      position: 'relative'
                    }}
                  >
                    Some CTA
                  </Button>
                ) : (
                  <></>
                )
              }
              logos={c.bragBarData.logos}
              logosToShow={c.bragBarData.logosToShow}
            />
          )
          break
        case 'carousel':
          section = (
            <Carousel
              className={className}
              key={c._id}
              id={c._id}
              heading={c.carouselData.heading}
              description={c.carouselData.description}
              items={c.carouselData.items}
            />
          )
          break
        case 'cta':
          section = (
            <CTA
              className={className}
              key={c._id}
              id={c._id}
              heading={c.ctaData.heading}
              subheading={c.ctaData.subheading}
              cta={
                <Button
                  variant="contained"
                  flex={1}
                  sx={{
                    width: '100%',
                    maxWidth: '500px',
                    textTransform: 'none',
                    borderRadius: '0px',
                    position: 'relative'
                  }}
                  //href={c.ctaData.cta}
                  href={pageData.redirectUrl}
                >
                  Some CTA
                </Button>
              }
            />
          )
          break
        case 'features':
          section = (
            <Features
              className={className}
              key={c._id}
              id={c._id}
              data={c.featuresData.data}
              bgcolor={c.featuresData.bgcolor}
            />
          )
          break
        case 'text':
          section = (
            <TextOnly className={className} key={c._id} id={c._id} heading={c.textData.heading} />
          )
          break
        case 'text-image':
          section = (
            <TextAndImage
              className={className}
              key={c._id}
              id={c._id}
              bgcolor={c.textImageData.bgcolor}
              innerBgcolor={c.textImageData.innerBgcolor}
              imgUrl={c.textImageData.imgUrl}
              variant={c.textImageData.variant}
              heading={c.textImageData.heading}
              description={c.textImageData.description}
              textAlign={c.textImageData.textAlign}
            />
          )
          break
        case 'image':
          section = (
            <ImageOnly className={className} key={c._id} id={c._id} imgUrl={c.imageData.imgUrl} />
          )
          break
        case 'steps':
          section = (
            <Steps
              className={className}
              key={c._id}
              id={c._id}
              heading={c.stepsData.heading}
              bgcolor={c.stepsData.bgcolor}
              description={c.stepsData.description}
              data={c.stepsData.data}
            />
          )
          break
        case 'page-header':
          section = (
            <PageHeader
              className={className}
              heading={c.pageHeaderData.heading}
              subheading={c.pageHeaderData.subheading}
              details={c.pageHeaderData.details}
              imgUrl={c.pageHeaderData.imgUrl}
              bgcolor={c.pageHeaderData.bgcolor}
              color={c.pageHeaderData.color}
              cta={
                <Button
                  variant="contained"
                  flex={1}
                  sx={{
                    width: '100%',
                    textTransform: 'none',
                    borderRadius: '0px',
                    position: 'relative',
                    ...buttonStyle,
                    color: c.pageHeaderData.buttonColor,
                    backgroundColor: c.pageHeaderData.buttonBgColor
                  }}
                  //href={c.pageHeaderData.cta}
                  href={pageData.redirectUrl}
                >
                  BUY THE BUNDLE
                </Button>
              }
              type={c.pageHeaderData.type}
              bline={c.pageHeaderData.bline}
              key={c._id}
              id={c._id}
            />
          )
          break
        case 'products':
          section = (
            <Products
              className={className}
              key={c._id}
              id={c._id}
              heading={c.productsData.heading}
              data={c.productsData.data}
              bgcolor={c.productsData.bgcolor}
              buttonColor={c.productsData.buttonColor}
              buttonBgColor={c.productsData.buttonBgColor}
              reviewsColor={c.productsData.reviewsColor}
            />
          )
          break
        case 'testimonials':
          section = (
            <Testimonials
              className={className}
              key={c._id}
              id={c._id}
              type={c.testimonialsData.type}
              heading={c.testimonialsData.heading}
              imgUrl={c.testimonialsData.imgUrl}
              reviews={c.testimonialsData.data}
            />
          )
          break
        case 'footer':
          section = (
            <Footer
              className={className}
              key={c._id}
              id={c._id}
              logo={c.footerData.logo}
              companyName={c.footerData.companyName}
              policyLink={c.footerData.policyLink}
              links={c.footerData.links}
            />
          )
          break
        case 'ingredients':
          section = (
            <Ingredients
              className={className}
              key={c._id}
              id={c._id}
              heading={c.ingredientsData.heading}
              description={c.ingredientsData.description}
              data={c.ingredientsData.data}
            />
          )
          break
        default:
          console.error(`Unknown component type "${c.type}"`)
          break
      }

      sectionsArray.push(
        <MetricWrapper
          key={c._id}
          componentId={c._id}
          pageId={pageData._id}
          metricMiddlemanId={metricMiddlemanId}
          addComponentMetric={addComponentMetric}
          setCurrComponentMetric={setCurrComponentMetric}
        >
          {section}
        </MetricWrapper>
      )
    })

    setDynamicComponents(<div style={{ backgroundColor: 'white' }}> {sectionsArray}</div>)
  }

  const renderLoader = () => <p>Loading</p>

  return (
    <ThemeProvider theme={theme}>
      {pageData && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageData.title}</title>
          <link rel="apple-touch-icon" sizes="180x180" href={pageData.favicons.appleTouchIcon} />
          <link rel="icon" type="image/png" sizes="32x32" href={pageData.favicons.favicon32x32} />
          <link rel="icon" type="image/png" sizes="16x16" href={pageData.favicons.favicon16x16} />
          <link rel="preconnect" href={config.API_URL} />
          <link rel="preconnect" href="https://storage.googleapis.com" />
          <link rel="preconnect" href="http://cdn.shopify.com" />
          {/* <link rel="manifest" href="/site.webmanifest" /> */}
        </Helmet>
      )}
      <CssBaseline />
      <Suspense fallback={renderLoader()}>{dynamicComponents}</Suspense>
    </ThemeProvider>
  )
}
