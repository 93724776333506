import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import axios from 'axios'
import { config } from '../../env'
const { API_URL } = config

function MetricWrapper({
  children,
  pageId,
  componentId,
  metricMiddlemanId,
  addComponentMetric,
  setCurrComponentMetric
}) {
  const [creatingMetric, toggleCreatingMetric] = useState(false)
  const [componentMetricId, setComponentMetricId] = useState()
  const [startTime, setStartTime] = useState()
  const [secondsInFocus, setSeconds] = useState(0)

  useEffect(() => {
    async function createMetric() {
      const componentMetricResponse = await axios.post(`${API_URL}/component-metrics`, {
        pageId,
        componentId
      })
      setComponentMetricId(componentMetricResponse.data.componentMetric._id)
      await axios.patch(`${API_URL}/metric-middlemen/update`, {
        middlemanId: metricMiddlemanId,
        componentMetricId: componentMetricResponse.data.componentMetric._id
      })
      toggleCreatingMetric(false)
      addComponentMetric(componentMetricResponse.data.componentMetric._id, {
        ctaType: 'secondary',
        focusedTimeSeconds: 0
      })
      setCurrComponentMetric(componentMetricResponse.data.componentMetric._id)
    }
    if (creatingMetric) {
      createMetric()
    }
  }, [creatingMetric])

  const onChange = (isVisible) => {
    if (isVisible) {
      if (!creatingMetric && !componentMetricId) {
        toggleCreatingMetric(true)
      }
      setStartTime(new Date())
      if (componentMetricId) {
        setCurrComponentMetric(componentMetricId)
      }
    } else {
      if (componentMetricId) {
        const endTime = new Date()
        const timeDiff = endTime - startTime
        const secondsElapsed = Math.round(timeDiff / 1000)
        addComponentMetric(componentMetricId, {
          ctaType: 'secondary',
          focusedTimeSeconds: secondsInFocus + secondsElapsed
        })
        setSeconds((prev) => prev + secondsElapsed)
      }
    }
  }

  return (
    <VisibilitySensor onChange={onChange} scrollCheck>
      {children}
    </VisibilitySensor>
  )
}

MetricWrapper.propTypes = {
  children: PropTypes.node,
  pageId: PropTypes.string,
  componentId: PropTypes.string,
  metricMiddlemanId: PropTypes.string,
  addComponentMetric: PropTypes.func,
  setCurrComponentMetric: PropTypes.func
}

export default MetricWrapper
