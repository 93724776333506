function convertMetrics(currComponentMetricId, metricsObject) {
  metricsObject[currComponentMetricId].ctaType = 'primary'
  return Object.keys(metricsObject).map((metricId) => ({
    componentMetricId: metricId,
    focusedTimeSeconds: metricsObject[metricId].focusedTimeSeconds,
    ctaType: metricsObject[metricId].ctaType
  }))
}

export default convertMetrics
