/*!

=========================================================
* Material Kit PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router'

import 'assets/scss/material-kit-pro-react.scss?v=1.10.0'
import '../node_modules/video-react/dist/video-react.css' // import css

// pages for this product
import SectionsPage from 'views/SectionsPage/SectionsPage.js'

var hist = createBrowserHistory()

import ReactGA from 'react-ga'
const TRACKING_ID = 'UA-216371342-1' // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID)

// Note: this will not work if we add more paths to the router
ReactGA.pageview(location.pathname)

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/" component={SectionsPage} />
    </Switch>
  </Router>,
  document.getElementById('root')
)
