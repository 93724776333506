import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import MKBox from 'MKComponents/MKBox'

export default function Header({ logo, centerLogo, cta }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ py: '13px', pl: '80px', pr: '65px' }}
      bgcolor="#fff"
    >
      <Box display="flex" flexGrow={1} />
      {!cta && centerLogo && <Box display="flex" flexGrow={1} />}
      {logo && (
        <MKBox
          component="img"
          src={logo}
          borderRadius="lg"
          width="96px"
          height="48px"
          sx={{ marginRight: '15px' }}
        />
      )}
      {cta}
      <Box display="flex" flexGrow={1} />
    </Box>
  )
}

Header.defaultProps = {
  logo: undefined,
  centerLogo: false
}

Header.propTypes = {
  logo: PropTypes.string,
  centerLogo: PropTypes.bool,
  cta: PropTypes.node
}
